<template>
  <v-container id="dashboard" fluid tag="section">
    <v-form ref="form">
      <base-material-card icon="mdi-cart-check">
        <slot slot="title"><a @click="orderLink()">{{order.name}} ({{order.orderNumber}})</a></slot>
        <slot slot="rightButtons" v-if="orderItem.id">
          <toggle-button text="Edit" openIcon="mdi-pencil" @toggle="showEdit" :showing="showEditToggle"/>
        </slot>
          <div v-if="showEditToggle">
            <div v-if="editOrderItem.productId">
              <v-select label="Product" menu-props="auto" item-value="id" item-text="name" v-model="editOrderItem.productId" :items="products">
              </v-select>
              <text-field label="Option" v-model="editOrderItem.productOption">
                <slot slot="tooltip">Any option for this order item (e.g 'XL').</slot> 
              </text-field>
            </div>
            <div v-else>
              <v-select label="Event" menu-props="auto" item-value="id" item-text="name" v-on:change="reloadEvent" v-model="editOrderItem.eventId" :items="events">
              </v-select>

              <v-select label="Event Time" menu-props="auto" item-value="id" v-model="editOrderItem.eventTimeId" :items="eventTimes">
                <template v-slot:item="{ item }">{{utils.dateToShortString(item.start)}}</template>
                <template v-slot:selection="{ item }">{{utils.dateToShortString(item.start)}}</template>
              </v-select>

              <v-select label="Ticket Type" menu-props="auto" item-value="id" item-text="name" v-model="editOrderItem.ticketTypeId" :items="ticketTypes">
              </v-select>
            </div>

            <text-field label="Quantity" v-model="editOrderItem.quantity" :rules="[utils.integerRule]">
              <slot slot="tooltip">How many items or tickets.</slot> 
            </text-field>

            <v-row class="mt-5">
              <v-col cols="auto">
                <v-btn color="success" @click="updateOrderItem">Save</v-btn>
                <v-btn color="white" @click="showEditToggle = false">Cancel</v-btn>
              </v-col>
              <v-spacer/>
              <v-col cols="auto">
                <v-btn class="mr-0" color="error" @click="deleteOrderItem">Delete</v-btn>
              </v-col>
            </v-row>
          </div>
          <div v-else>
            <v-row>
              <v-col cols="4">Order Item for</v-col>
              <v-col cols="8">{{order.name}}</v-col>
            </v-row>
            <v-row>
              <v-col cols="4">Order Number</v-col>
              <v-col cols="8">
                <a @click="orderLink()"><b>{{order.orderNumber}}</b></a>
              </v-col>
            </v-row>
            <div v-if="orderItem && orderItem.productId">
              <v-row>
                <v-col cols="4">Product</v-col>
                <v-col cols="8">
                  <a @click="productLink()"><b>{{orderItem.productName}}</b></a>
                </v-col>
              </v-row>
              <div v-if="orderItem && orderItem.productOption">
                <v-row>
                  <v-col cols="4">Product Option</v-col>
                  <v-col cols="8">{{orderItem.productOption}}</v-col>
                </v-row>
              </div>
            </div>
            <div v-else>
              <v-row>
                <v-col cols="4">Event</v-col>
                <v-col cols="8">
                  <a @click="eventLink()"><b>{{orderItem.eventName}}</b></a>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">Ticket Type</v-col>
                <v-col cols="8">
                  <a @click="ticketTypeLink()"><b>{{orderItem.ticketTypeName}}</b></a>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">Event Time</v-col>
                <v-col cols="8">
                  <a @click="eventTimeLink()"><b>{{utils.dateToShortString(orderItem.eventTimeStart)}}</b></a>
                </v-col>
              </v-row>
            </div>
            <v-row>
              <v-col cols="4">Quantity</v-col>
              <v-col cols="8">{{orderItem.quantity}}</v-col>
            </v-row>
            <v-row>
              <v-col cols="4">Value</v-col>
              <v-col cols="8">{{utils.priceDisplay(orderItem.value)}}</v-col>
            </v-row>
          </div>
      </base-material-card>
    </v-form>

    <ticket-list v-if="!orderItem.productId && order.boxOfficeId" :order="order" :orderItem="orderItem"/>

  </v-container>

</template>
  
<script>
  import Api from '@/services/Api.js'; 
  import Utils from '@/services/Utils.js';
  import Store from '@/services/Store.js';

  export default {
    name: 'Order',

    components: {
      BaseMaterialCard: () => import('@/components/base/MaterialCard'),
      Tooltip: () => import('./components/Tooltip'),
      TextField: () => import('./components/TextField'),
      ToggleButton: () => import('./components/ToggleButton'),
      TicketList: () => import('./TicketList'),
    },  

    data () {
      return {
        showCreateOrderItemToggle: false,
        user: {},
        boxOffice: {},
        event: {},
        ticketType: {},
        product: {},
        eventTime: {},
        order: {},
        orderItem: {},
        eventTimes: [],
        events: [],
        products: [],
        statuses: [],
        reloadEventFlag: false,
        ticketTypes: [],
        showEditToggle: false,
      }
    },

    created() {
      this.orderItem.id = this.$route.params.id;
      this.load();
    },
    
    computed: {
      utils: function () {
        return Utils;
      },
    },
    
    methods: {

      async load() {
        this.orderItem = await Api.post(this, "OrderItem", "read", this.orderItem);
        console.log("orderItem", this.orderItem.productId)
        this.order = await Api.post(this, "Order", "read", {id:this.orderItem.orderId});
        if (this.orderItem.productId) {
          this.product = await Api.post(this, "Product", "read", {id:this.orderItem.productId});
        } else {
          this.ticketType = await Api.post(this, "TicketType", "read", {id:this.orderItem.ticketTypeId});
          this.eventTime = await Api.post(this, "EventTime", "read", {id:this.orderItem.eventTimeId});
          this.loadEvent(this.orderItem.eventId);
        }
        this.boxOffice = await Api.post(this, "BoxOffice", "read", {id:this.orderItem.boxOfficeId});
        this.events = await Api.post(this, "Event", "list", this.boxOffice);
        this.products = await Api.post(this, "Product", "list", this.boxOffice);
        Store.store(this.boxOffice, this.event);
      },

      eventLink() {
        this.$router.push("/dashboard/EventDetails/" + this.event.id);
      },

      orderLink() {
        this.$router.push("/dashboard/Order/" + this.order.id);
      },

      eventTimeLink() {
        this.$router.push("/dashboard/EventTime/" + this.eventTime.id);
      },

      ticketTypeLink() {
        this.$router.push("/dashboard/TicketType/" + this.ticketType.id);
      },

      productLink() {
        this.$router.push("/dashboard/Product/" + this.product.id);
      },

      async reloadEvent(id) {
        this.reloadEventFlag = true;
        this.orderItem.eventTimeId = null;
        this.orderItem.ticketTypeId = null;
        this.loadEvent(id);
      },

      async loadEvent(id) {
        this.event.id = id;
        this.event = await Api.post(this, "Event", "read", this.event);
        this.eventTimes = await Api.post(this, "EventTime", "list", this.event);
        this.ticketTypes = await Api.post(this, "TicketType", "list", this.event);
      },

      async updateOrderItem() {
        if (! await Utils.validate(this.$refs.form) ) {
          return;
        }
        this.orderItem.quantity = Utils.priceToNumber(this.editOrderItem.quantity);
        console.log("orderItem", this.editOrderItem.productId)
        this.orderItem = await Api.post(this, "OrderItem", "update", this.editOrderItem);
        this.load();
        this.hideEdit();
      },

      async deleteOrderItem() {
        await Api.post(this, "OrderItem", "delete", this.orderItem);
        this.$router.push("/Dashboard/Order/" + this.orderItem.orderId);
      },

      showEdit(show) {
        if (show) {
          this.editOrderItem = Utils.jsonCopy(this.orderItem);
        }
        this.showEditToggle = show;
      },

      hideEdit() {
        this.showEditToggle = false;
      },

    }
  }
</script>
